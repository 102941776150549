<!-- ProductDetails.vue -->
<template>
  <div class="mt-7">
    <div v-if="isLoggedIn" class="row mt-4">
      <b-container class="bv-example-row">
      <div v-for="customer in customers" :key="customer.id">
        <div class="card mb-4 card-clickable" @click="editCustomer(customer)">
          <div class="row">
            <div class="col-xl-12">
              <div class="p-4">
                <div class="avatar-sm  mb-3 mt-1">
                  <span :class="`avatar-title rounded-circle bg-soft store-c font-size-16`"
                  >{{customer.company.charAt(0)}}</span>
                </div>
                <h1 class="text-truncate pb-1 font-weight-bold">{{customer.company}}</h1>
              </div>
            </div>

            <div class="col-xl-12">
              <div class="p-4 ">
                <div class="row">
                  <div class="col-md-3 col-xs-12">
                      <p class="text-muted mb-2 text-truncate">Contact</p>
                      <h6>{{ customer.contact}}</h6>
                  </div> 
                  <div class="col-md-3 col-xs-12">
                      <p class="text-muted mb-2 text-truncate">Email</p>
                      <h6>{{ customer.email}}</h6>
                  </div>   
                  <div class="col-md-3 col-xs-12">
                      <p class="text-muted mb-2 text-truncate">Address</p>
                      <h6>{{ customer.address}}</h6>
                  </div> 
                  <div class="col-md-3 col-xs-12">
                      <p class="text-muted mb-2 text-truncate">Telephone</p>
                      <h6>{{ customer.telephone}}</h6>
                  </div> 
            
                </div>
                <div class="btn-group btn-group-sm mb-2" role="group" aria-label="">
                <button @click="editCustomer(customer)" class="btn btn-secondary font-weight-bold">
                      Edit
                    </button>
                </div>
              </div>
            </div>
      </div>
    </div>
      
        <div class="col-md-6 offset-md-3 col-sm-10 offset-sm-1" v-if="editingCustomerId === customer.id">
          <form @submit.prevent="updateCustomer">


            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="company" class="control-label">Company</label>
                <input type="text"  id="company" class="form-control form-control-lg"   
                v-model="editedCustomer.company" required />
              </div>
              <div class="form-group col-md-6">
                <label for="company" class="control-label">Contact</label>
                <input type="text" id="contact" class="form-control form-control-lg"   
                v-model="editedCustomer.contact" required />
              </div>

            </div>

            <!--<label for="company">Company:</label>
            <input type="text" id="company" v-model="editedCustomer.company" required><br>
            <label for="contact">Contact:</label>
            <input type="text" id="contact" v-model="editedCustomer.contact" required><br>-->




            <!--<label for="email">Email:</label>
            <input type="email" id="email" v-model="editedCustomer.email" required><br>
            <label for="address">Address:</label>
            <input type="text" id="address" v-model="editedCustomer.address" required><br>-->

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="email" class="control-label">Email</label>
                <input type="text" id="email" class="form-control form-control-lg"   
                v-model="editedCustomer.email" required />
              </div>
              <div class="form-group col-md-6">
                <label for="address" class="control-label">Address</label>
                <input type="telephone" id="address" class="form-control form-control-lg"
                  v-model="editedCustomer.address" required />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="email" class="control-label">Telephone</label>
                <input type="text" id="telephone" class="form-control form-control-lg"   
                v-model="editedCustomer.telephone" required />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6 offset-md-3">
                <button type="submit" class="btn btn-secondary btn-lg font-weight-bold">
                  Update
                </button>
              </div>

              <div class="form-group col-md-6 offset-md-3">
                <button @click="cancelEdit" class="btn btn-secondary  font-weight-bold">
                  Cancel
                </button>
              </div>

            </div>


            
            <!--<label for="telephone">Telephone:</label>
            <input type="text" id="telephone" v-model="editedCustomer.telephone" required><br>
            <button type="submit">Update</button>
            <button @click="cancelEdit">Cancel</button>-->
          </form>
        </div>
      </div>
      </b-container>
    </div>
    <div v-else class="mt-4"> 
      <div class="row ">
        <div class="col-md-8">
          <h1 class="font-weight-bold">Account</h1>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-8">
        <h5 class="font-weight-bold">Please login to view your account.</h5>
          <router-link to="/login" class="btn btn-dark btn-black  font-weight-bold" tag="button">
            Log in
          </router-link>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref,firebaseAuth } from '../../config/firebaseConfig'
import { mapActions, mapGetters } from 'vuex'
export default {

  data() {
    return {
      customers: [],
      editingCustomerId: null,
      editedCustomer: {
        id: "",
        company: "",
        contact: "",
        email: "",
        address: "",
        telephone: ""
      }
    };
  },

  computed: {
   
   ...mapGetters([
     'isLoggedIn',
     'currentUser',
   ]),

   
 },

  
  mounted() {
  
    if (this.isLoggedIn){
      firebaseAuth().onAuthStateChanged(user => {
                if (user) {
                  const userId = user.uid;
                  const customersRef = ref.child("customers");
                  customersRef.child(userId).once("value", snapshot => {
                    if (snapshot.exists()) {
                      const customer = snapshot.val();
                      customer.id = userId;
                      this.customers.push(customer);
                    }
                  });
                }
              });

    }/*else {
              this.addMessage({
                messageClass: 'warning',
                message: 'Please login to view your account',
              })
            }*/
          
  },
  created() {
   
   
    
  },
  methods: {

    ...mapActions([
      'addMessage',

    ]),
    editCustomer(customer) {
      this.editingCustomerId = customer.id;
      this.editedCustomer = { ...customer };
      console.log('editCustomer');
    },
    updateCustomer() {
      const customerId = this.editedCustomer.id;
      const customersRef = ref.child("customers");
    
      const user = firebaseAuth().currentUser;
      console.log(user);
      user.updateEmail("jared@blacksnow.co.zaa").then(() => {
        console.log("DONE");
      }).catch((error) => {
        console.log(error);
      });

      customersRef.child(customerId).update(this.editedCustomer)
        .then(() => {
          const updatedCustomerIndex = this.customers.findIndex(cust => cust.id === customerId);
          if (updatedCustomerIndex !== -1) {
            this.customers.splice(updatedCustomerIndex, 1, { ...this.editedCustomer });
          }
          this.cancelEdit();
        })
        .catch(error => {
          console.error("Error updating customer:", error);
        });
    },
    cancelEdit() {
      this.editingCustomerId = null;
      this.editedCustomer = {
        id: "",
        company: "",
        contact: "",
        email: "",
        address: "",
        telephone: ""
      };
    }
  },
};
</script>
<style>
.btn-blue{
  color: #1967d2 !important;
  background-color: #e8f0fe !important;
  border-color: #e8f0fe !important;
  border-radius: 2rem !important;
  font-weight: 600 !important;
}

.mt-7{
  margin-top: 7rem ;
}

.card-link{
    font-weight: 700 !important;
    color: #1967d2 !important;
  
}
.color-grey{
    color: #616161
}

.form-control {
    background: #f7f7f7 !important;
    border-radius: 4px !important;
    border: 1px solid #f7f7f7 !important;
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    display: block;
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    padding: 0 0 0 12px;
    margin-bottom: 16px;
    transition: box-shadow .15s ease-out .15s,border-color .15s ease-out,background .15s ease-out .15s,color .15s ease-out;
    width: 100%;
}

.input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
    color: fieldtext !important;
}

</style>
