<template>
	<tr>
		<td data-th="Product">
			<div class="row">
				<div class="col-sm-6">
					<!-- <h4 class="nomargin">{{ cartItem.name }} <span class="text-color-grey"><small>({{ storeName }})</small></span></h4> -->
					<h4 class="nomargin">{{ cartItem.name }}</h4>
					<!-- <p>{{ cartItem.size }}</p> -->
					<!--<p>{{ storeName }}</p>-->
				</div>
			</div>
		</td>
		<td data-th="Size">{{ cartItem.size }}</td>
		<!-- <td data-th="Price">{{ cartItem.price }}</td> -->
		<td data-th="Quantity">
			<div class="row justify-content-center">
				<div>
					<input type="text" inputmode="numeric"  style = "Width: 55px !important;" class="form-control form-control-sm text-center mb-0" :value="cartItem.quantity"
					@input="updateQuantity" min="" />
				</div>
				<div class="" >
					<button class="btn btn-sm" @click="increaseQuantity">
						<i class="fas fa-plus-circle fa-lg"></i>
					</button>
				</div>
				<div>
					<button class="btn btn-sm" @click="decreaseQuantity">
						<i class="fas fa-minus-circle fa-lg"></i>
					</button>
				</div>
			</div>
		</td>
		<!-- <td data-th="Subtotal" class="text-center">R {{ subtotal }}</td> -->
		<td class="text-center">
			<button class="btn btn-sm" @click="removeItem">
				<span class="material-icons">delete</span>
			</button>
		</td>
	</tr>
</template>
  
<script>
import { mapActions } from 'vuex';
import { ref } from '../../config/firebaseConfig';

export default {
	data() {
    return {
      storeName:'' 
    };
  },
	props: ['cartItem'],

	created() {
    this.fetchStoreDetails(this.cartItem.storeId);
  },
	computed: {
		subtotal() {
			let subT = this.cartItem.quantity * this.cartItem.price;
			return subT.toFixed(2);
		}
	},
	methods: {
		...mapActions(['updateCart', 'removeItemInCart']),
		removeItem() {
			// console.log(this.cartItem)
			this.removeItemInCart({
				storeId: this.cartItem.storeId,
				item: this.cartItem
			});
		},
		updateQuantity(event) {
			const quantity = parseInt(event.target.value);
			this.updateCart({
				storeId: this.cartItem.storeId,
				item: this.cartItem,
				quantity,
				isAdd: false
			});
		},
		increaseQuantity() {
			const newQuantity = this.cartItem.quantity + 1;
			this.updateCart({
				storeId: this.cartItem.storeId,
				item: this.cartItem,
				quantity: newQuantity,
				//isAdd: true
			});
		},
		decreaseQuantity() {
			if (this.cartItem.quantity > 1) {
				const newQuantity = this.cartItem.quantity - 1;
				this.updateCart({
					storeId: this.cartItem.storeId,
					item: this.cartItem,
					quantity: newQuantity,
					isAdd: false
				});
			}
		},
		fetchStoreDetails(storeId) {
			// console.log('CartItem', this.cartItem);
			ref.child(`stores/${storeId}`)
				.once('value')
				.then(snapshot => {
				const store = snapshot.val();
				if (store) {
					store.id = storeId;
					this.storeName = store.name;
					this.storeEmail = store.email;
					
				} else {
					throw new Error(`Store with ID ${storeId} does not exist.`);
				}
				});
    },
	}
};
</script>
  
<style lang="scss" scoped>
.mt-5 {
	margin-top: 5rem;
}

.material-icons {
	font-size: 16px;
}
.text-color-grey{
color: gray;
	
}
</style>
  

<!--<template>


	<tr>
		<td data-th="Product">
			<div class="row">
				<div class="col-sm-6">
					<h4 class="nomargin">{{ cartItem.name }}</h4>
					<p>{{ cartItem.size }}</p>
					<p>{{ cartItem.storeId }}</p>
					<button class="btn  btn-sm" @click="removeItem"><span class="material-icons">
                    delete
</span></button>
				</div>
			</div>
		</td>
		<td data-th="Price">{{ cartItem.price }}</td>
		<td data-th="Quantity">
			<input type="number" class="form-control form-control-sm text-center"
				:value="cartItem.quantity"
				@input="updateQuantity"
				min="0">
		</td>
		<td data-th="Subtotal" class="text-center">R {{ subtotal }}</td>
		
	</tr>



</template>

<script>
	import { mapActions } from 'vuex';
	export default {
		props: ['cartItem'],
		computed: {
			subtotal() {
				return this.cartItem.quantity * this.cartItem.price;
			}
		},
		methods: {
			...mapActions(['updateCart', 'removeItemInCart']),
			removeItem() {
				let vm = this;
				this.removeItemInCart({
					item: vm.cartItem
				});
			},
			updateQuantity(event) {
				let vm = this;
				this.updateCart({
					item: vm.cartItem,
					quantity: parseInt(event.target.value),
					isAdd: false
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
.mt-5 {
  margin-top: 5rem;
}

.material-icons {
     font-size: 16px; 
}

</style>-->
