<template>
  <div class="row mt-7">
    <b-container>
      <div class="col-md-4 offset-md-4 col-sm-10 offset-sm-1">
      <form id="login-form" role="form" style="display: block;" @submit.prevent="onSubmit">
        <h1 class="text-center font-weight-bold mb-5">Forgot Password</h1>
        <div class="form-group">
          <label for="password" class="control-label">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            class="form-control form-control-lg"
            v-model="email"
            required
          />
        </div>
        
        <div class="form-group">
          <div class="row">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-secondary btn-lg font-weight-bold" style="width: 100%">
           
           Send
         </button>

            </div>
          </div>
          
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-lg-6">
              <div class="text-center font-weight-bold">
                <router-link to="/login">
                  <a>Login</a>
                </router-link>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="text-center font-weight-bold">
                <router-link to="/register">
                  <a>Register</a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    </b-container>
    
  </div>
</template>

<script>
import {
  mapActions
} from 'vuex';
import { firebaseAuth  } from '../../config/firebaseConfig';
export default {
  data() {
    return {
      email: '',
      
    }
  },
  methods: {
    ...mapActions(['addMessage', 'clearMessage', 'loginWithEmail']),

    onSubmit() {
      firebaseAuth().sendPasswordResetEmail(this.email)
        .then(() => {
          alert('Password reset email sent. Please check your inbox.');
          // Optionally, you can redirect the user to another page here
        })
        .catch((error) => {
          alert(`Failed to reset password: ${error.message}`);
        });
    },
  
  }
}
</script>

<style scoped>

.mt-7{
  top: 50% !important
}

.control-label {
    color: #656b73;
    text-transform: none;
    letter-spacing: 0;
    text-align: inherit;
    height: 16px;
    font-size: 14px !important;
    font-weight: 600;
}

.form-control {
    background: #f7f7f7;
    border-radius: 4px;
    border: 1px solid #f7f7f7;
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    display: block;
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    padding: 0 0 0 12px;
    margin-bottom: 16px;
    transition: box-shadow .15s ease-out .15s,border-color .15s ease-out,background .15s ease-out .15s,color .15s ease-out;
    width: 100%;
}
a{
  color:#1967d2 !important
}

a:hover {
  text-decoration: none;
}
</style>
