const state = {
	cartItemList: [
		// {
		// 	id: 1,
		// 	title: 'macbook Retina 13.3" ME662 (2013)',
		// 	thumbnail_url: 'http://media.bizwebmedia.net//sites/72783/data/images/2016/2/4713895macbook_pro_retina.png',
		// 	price: 1000,
		// 	quantity: 10,
		// 	description: "3.0GHz Dual-core Haswell Intel Core i5 Turbo Boost up to 3.2 GHz, 3MB L3 cache 8GB (two 4GB SO-DIMMs) of 1600MHz DDR3 SDRAM"
		// },{
		// 	id: 2,
		// 	title: 'Macbook Pro 13.3" Retina MF841LL/A',
		// 	thumbnail_url: 'http://media.bizwebmedia.net//sites/72783/data/images/2015/11/3220113retina13.jpg',
		// 	price: 1200,
		// 	quantity: 15,
		// 	description: 'Macbook Pro 13.3" Retina MF841LL/A Model 2015 Option Ram Care 12/2016'
		// },{
		// 	id: 3,
		// 	title: 'Macbook Pro 15.4" Retina MC975LL/A Model 2012',
		// 	thumbnail_url: 'http://media.bizwebmedia.net//sites/72783/data/images/2015/7/2913337mf841_13_inch_2_9ghz_with_retina_display_early_2015.png',
		// 	price: 1800,
		// 	quantity: 1,
		// 	description: "3.0GHz Dual-core Haswell Intel Core i5 Turbo Boost up to 3.2 GHz, 3MB L3 cache 8GB (two 4GB SO-DIMMs) of 1600MHz DDR3 SDRAM"
		// },{
		// 	id: 4,
		// 	title: 'Retina MacBook Pro 13 inch MF841',
		// 	thumbnail_url: 'http://media.bizwebmedia.net//sites/72783/data/images/2016/2/4713895macbook_pro_retina.png',
		// 	price: 1000,
		// 	quantity: 20,
		// 	description: "2.9 Ghz Dual-Core Intel Core i5 Broadwell Tubro boost up to 3.3 GHz with L3 3MB cache"
		// }
	]
}

const mutations = {
	'UPDATE_CART' (state, {storeId, item, quantity, isAdd}) {
		// console.log(state.cartItemList.find(element => element.storeId == storeId))
		// for(var i in state.cartItemList){
		// 	console.log(i); // alerts key
		// 	console.log(state.cartItemList[i]); //alerts key's value
		// }
		// const result = state.cartItemList.filter(obj => {
		// 	// return obj[storeId] === storeId
		// 	// console.log("Object",  Object.prototype.hasOwnProperty.call(obj, storeId))
		// 	console.log("Object",obj)
		// })
		// console.log(result)
		const storeRecord = state.cartItemList.find(element => element.storeId == storeId)
		// console.log('Record', state.cartItemList.find(element => element[storeId] = storeId))
		if (storeRecord) {
			// const record = storeRecord.find(element => {console.log(element)});
			const record = storeRecord[storeId];
			const storeItem = record.find(element => element.id == item.id)
			if(storeItem){
				if (isAdd) {
					storeItem.quantity += quantity;
				} else {
					storeItem.quantity = quantity;
				}
			}else{
				record.push({
					...item,
					quantity
				})
			}
		} else {
			// console.log("No", storeId)
			const storeArray = {storeId:storeId};
			storeArray[storeId] = [{
				...item,
				quantity
			}]
			state.cartItemList.push(storeArray);
		}
	},
	// 'UPDATE_CART' (state, {item, quantity, isAdd}) {
	// 	const record = state.cartItemList.find(element => element.id == item.id);
	// 	if (record) {
	// 		if (isAdd) {
	// 			record.quantity += quantity;
	// 		} else {
	// 			record.quantity = quantity;
	// 		}
	// 	} else {
	// 		state.cartItemList.push({
	// 			...item,
	// 			quantity
	// 		});
	// 	}
	// },
	'SET_CART' (state, productList) {
		if (productList) {
			state.cartItemList = productList;
		}
	},
	'REMOVE_CART_ITEM' (state, {storeId, item}) {
		const storeRecord = state.cartItemList.find(element => element.storeId == storeId);
		const record = storeRecord[storeId];
		const itemrow = record.find(element => element.id == item.id);
		record.splice(record.indexOf(itemrow), 1);
	}
}

const actions = {
	clearCart: ({commit}) => {
		commit('SET_CART', []);
	}
}

const getters = {
	cartItemList: (state) => {
		return state.cartItemList;
	},
	cartValue: (state) => {
		let res = 0;
		state.cartItemList.map(item => {
			res += item.price * item.quantity;
		});
		return res.toFixed(2);
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}
