<template>
  <div class="container mt-7">
    <b-container class="bv-example-row">
      <h1 class="font-weight-bold">Order</h1>
      <h6 class="font-weight-bold">Order No: {{ order.orderNo }}</h6>
      <p>Supplier: {{ order.storeName }}</p>
      <p>Date: {{ order.date }}</p>
      <p>Status: {{ order.status }}</p>
        
    </b-container>
    <b-container class="bv-example-row">
      <b-table :items="order.items" :fields="fields" hover small>
        <!-- Define your table columns here -->
        <template #cell(itemName)="row">
          {{ row.item.itemName }}
        </template>
        <template #cell(size)="row">
          {{ row.item.size }}
        </template>
        <template #cell(quantity)="row">
          {{ row.item.quantity }}
        </template>
      </b-table>
      <!-- <p class="font-weight-bold">Total: R {{ order.orderTotal }}</p> -->
    </b-container>
    <b-container class="bv-example-row mt-4" v-if="order.notes">
        <h6>Additional Notes:</h6>
        <p class="">{{ order.notes }}</p>
    </b-container>     
  </div>
</template>

    
    <script>
 
    
    
    
    export default {
      props: ['route'],
    data() {
        return {
          order: {},

          fields: [
        { key: 'name', label: 'Name' },
        { key: 'size', label: 'Size' },
        { key: 'quantity', label: 'Quantity' },
      ],
        };
    },
    

  created() {
    //this.fetchOrders();
  },

  mounted() {

    console.log("orders")
 
    this.order = this.$route.params.order;
    console.log(this.$route.params)
    // console.log(this.order.items)
  },

  
    }
    </script>
    
    <style>

.h1 {
    font-size: 2.8rem;
    font-weight: 700 !important;
}
    .loadingItem {
      align-items: center;
      justify-content: center;
      display: flex;
    }
    
    .action-panel {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    .avatar-sm {
    height: 3rem;
    width: 3rem;
}
.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f8f9fa;
    color: black;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 900;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

.card-link{
    font-weight: 700 !important;
    color: #1967d2 !important;
  
}

.blue-font{
  color: #1967d2 !important;
}

.mt-7{
    margin-top: 7rem;
}

label{
    font-size: 0.5rem !important;
}


.btn-blue{
  color: #1967d2 !important;
  background-color: #e8f0fe !important;
  border-color: #e8f0fe !important;
  border-radius: 2rem !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  -webkit-box-shadow:none !important;
}

.btn-blue:focus, .btn-blue:focus{
  color: #1967d2 !important;
  background-color: #e8f0fe !important;
  border-color: #e8f0fe !important;
  border-radius: 2rem !important;
  font-weight: 600 !important;
  box-shadow: 0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
  -webkit-box-shadow:0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
}

.form-control:focus, .form-control:focus{
 
  box-shadow: 0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
  -webkit-box-shadow:0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
}

.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #349368;
    border-color: #1967d2;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 187, 144, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(91, 187, 144, 0.5);
}
.icon-name{
    box-sizing: border-box;
    left: 50%;
    padding: 0 8px;
    position: absolute;
    top: 88px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    width: 100%;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.icon-asset{
    display: block;
    font-size: 40px;
    left: 50%;
    position: absolute;
    top: 40px;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    max-width: 88px;
}
.material-icons{
    font-size: 60px;
}

.view-btn{

    border-color:transparent !important;
    background-color:transparent !important
}
.icon-out{
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background: transparent;
    border: 0;
    border-radius: 8px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    height: 128px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 96px;
}


.card-clickable {
  cursor: pointer;
  transition: transform 0.2s;
}

.card-clickable:hover,
.card-clickable:focus,
.card-clickable:active {
  transform: scale(1.02);
}

.card-clickable .card-body {
  transition: background-color 0.2s;
}

.card-clickable:hover .card-body,
.card-clickable:focus .card-body,
.card-clickable:active .card-body {
  background-color: rgba(0, 0, 0, 0.05);
}

.control-label {
    color: #656b73;
    text-transform: none;
    letter-spacing: 0;
    text-align: inherit;
    height: 16px;
    font-size: 12px;
    font-weight: 600;
}

    </style>
    