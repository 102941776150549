<template>
  <div class="mt-7">
    <b-container>
      <div class="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
        <form id="register-form" role="form" @submit.prevent="onSubmit">
          <h1 class="text-center font-weight-bold mb-5">Register</h1>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="company" class="control-label">Company</label>
              <input type="text" name="company" id="company" class="form-control form-control-lg" placeholder="" value
                v-model="company" required />
            </div>
            <div class="form-group col-md-6">
              <label for="company" class="control-label">Contact</label>
              <input type="text" name="contact" id="contact" class="form-control form-control-lg" placeholder="" value
                v-model="contact" required />
            </div>

          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="address" class="control-label">Address</label>
              <input type="text" name="address" id="address" class="form-control form-control-lg" placeholder="" value
                v-model="address" required />
            </div>
            <div class="form-group col-md-6">
              <label for="address" class="control-label">Telephone</label>
              <input type="telephone" name="telephone" id="telephone" class="form-control form-control-lg" placeholder=""
                value v-model="telephone" required />
            </div>
          </div>

          <div class="form-row">

            <div class="form-group col-md-6">
              <label for="email" class="control-label">Email</label>
              <input type="email" name="email" id="email" class="form-control form-control-lg" placeholder="" value
                v-model="email" required />
            </div>
            <div class="form-group col-md-6">
              <label for="password" class="control-label">Password</label>
              <input type="password" name="password" id="password" class="form-control form-control-lg" placeholder=""
                v-model="password" required />
            </div>

          </div>
          <div class="form-row">
            <div class="form-group col-md-6 offset-md-3">
              <button type="submit" class="btn btn-secondary btn-lg font-weight-bold" style="width: 100%"
                :disabled="isLoading">
                <i v-if="isLoading" class="fa fa-spinner fa-spin" />
                Register
              </button>
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-md-6 offset-md-3">
              <div class="row">
                <div class="col-lg-12">
                  <div class="text-center font-weight-bold">
                    <router-link to="/login">
                      <a>Login</a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </form>
      </div>

    </b-container>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      company: '',
      contact: '',
      address: '',
      telephone: null,
      email: '',
      password: '',
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['clearMessage', 'addMessage', 'registerByEmail']),
    onSubmit() {
      this.isLoading = true
      let data = {
        email: this.email,
        password: this.password,
        company: this.company,
        contact: this.contact,
        address: this.address,
        telephone: this.telephone,

      }
      this.registerByEmail(data).then(() => {
        this.clearMessage();
        this.$router.push({ name: 'mainpage' });
      })
        .catch((error) => {
          // console.log('register error', error);
          let message_obj = {
            message: error.message,
            messageClass: "danger",
            autoClose: true
          }
          this.addMessage(message_obj);
        }).then(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.mt-7 {
  top: 50% !important
}

a {
  color: #1967d2 !important
}

a:hover {
  text-decoration: none;
}

.control-label {
  color: #656b73;
  text-transform: none;
  letter-spacing: 0;
  text-align: inherit;
  height: 16px;
  font-size: 14px !important;
  font-weight: 600;
}


.form-control {
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  display: block;
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  padding: 0 0 0 12px;
  margin-bottom: 16px;
  transition: box-shadow .15s ease-out .15s, border-color .15s ease-out, background .15s ease-out .15s, color .15s ease-out;
  width: 100%;
}

.input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
  color: fieldtext !important;
}
</style>
