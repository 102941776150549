
<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-md-12">
        <h1 class="font-weight-bold">Orders</h1>
      </div>
      <div class="col-md-3">
        <div v-if="isLoggedIn" class="form-group">
          <label for="name" class="control-label"> Filter orders:</label>
          <input type="text" class="form-control" id="name" v-model="searchKeyword">
        </div>
      </div>
    </div>
    <div class="row p-4 justify-content-center" v-if="isLoggedIn && isLoading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    <b-container v-if="isLoggedIn" class="bv-example-row">
      <b-row v-if="orders.length > 0">
        <b-table :items="filteredOrders" :fields="fields" hover small>
          <template #cell(orderNo)="row">
            <b-link class="blue-font font-weight-bold" @click="viewOrderDetails(row.item)">{{ row.item.orderNo }}</b-link>
          </template>
          <template #cell(supplier)="row">
            {{ row.item.storeName }}
          </template>
          <template #cell(orderStatus)="row">
            {{ row.item.status }}
          </template>
          <template #cell(date)="row">
            {{ row.item.date }}
          </template>
        </b-table>
      </b-row>
      <div class="mt-4" v-if="orders.length === 0 && !isLoading">
        <h4 class="font-weight-bold">No orders found.</h4>
      </div>
    </b-container>
    <div v-else class="row mt-4">
      <div class="col-md-8">
        <h5 class="font-weight-bold">Please login to view your orders.</h5>
        <router-link to="/login" class="btn btn-dark btn-black  font-weight-bold" tag="button">
          Log in
        </router-link>
      </div>
    </div>
  </div>
</template>
    
<script>

import { ref } from '../../config/firebaseConfig'
//import { onValue } from 'firebase';
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'


export default {
  data() {
    return {
      isLoading: true,
      orders: [],
      fields: [
        { key: 'orderNo', label: 'Order No' },
        { key: 'supplier', label: 'Supplier' },
        { key: 'orderStatus', label: 'Order Status' },
        { key: 'date', label: 'Date' },
      ],
      expandedRows: [],
      filtered:{},
      searchKeyword: "",
    };
  },
  computed: {

    ...mapGetters([
      'isLoggedIn',
      'currentUser',
    ]),

    filteredOrders() {
      if (!this.orders && !this.searchKeyword) {
        return [];
      }else if(this.orders && !this.searchKeyword){
        return this.orders;
      }else{
        const keyword = this.searchKeyword.toLowerCase();
        return this.orders.filter(
          (order) =>
            // console.log(keyword, order.status, order.orderNo, order.storeName, order.date)
            order.orderNo.toLowerCase().includes(keyword) ||
            order.storeName.toLowerCase().includes(keyword) ||
            order.date.toLowerCase().includes(keyword) ||
            order.status.toLowerCase().includes(keyword)
        );
      }
    },
  },

  created() {
    //this.fetchOrders();
  },

  mounted() {
    this.fetchOrders();
  },
  methods: {
    ...mapActions([
      'addMessage',

    ]),
    viewOrderDetails(order) {
      // Navigate to the order details page with order.items
      // Implement your logic here to open a new page or component and pass the order.items data
      // console.log("Order Details:", order);
      this.$router.push({ name: 'order', params: { order } });
    },
    fetchOrders() {
      /*if (this.isLoggedIn) {

        const ordersRef = ref.child('orders/' + this.currentUser.uid);
        ordersRef.on('value', (snapshot) => {
          const ordersData = snapshot.val();
          const orders = [];

          for (const orderId in ordersData) {
            const order = ordersData[orderId];
            orders.push({
              orderNo: order.orderNo,
              orderTotal: order.orderTotal,
              date: moment(order.date).format('MMMM Do YYYY'),
              items: order.items,
            });
          }
          this.orders = orders;
        });
      }*/
      if (this.isLoggedIn) {
        const ordersRef = ref.child('orders/' + this.currentUser.uid);
        ordersRef.orderByChild('date').on('value', (snapshot) => {
          const ordersData = snapshot.val();
          const orders = [];

          // console.log(ordersData);
          for (const orderId in ordersData) {
            const order = ordersData[orderId];
            orders.push({
              orderNo: order.orderNo,
              storeName: order.storeName,
              // orderTotal: order.orderTotal,
              date: moment(order.date).format('MMMM Do YYYY - HH:mm A'),
              items: order.items,
              notes:order.notes,
              status: order.status
            });
          }

          // Sort orders by date in descending order
          orders.sort((a, b) => {
            const dateA = moment(a.date, 'MMMM Do YYYY HH mm');
            const dateB = moment(b.date, 'MMMM Do YYYY HH mm');
            return dateB - dateA;
          });

          this.orders = orders;
          this.isLoading = false;
        });
      }

    },

    toggleRow(index) {
      // console.log(this.expandedRows[index]);
      this.$set(this.expandedRows, index, !this.expandedRows[index]);
    },
    reorder(order) {
      // TODO: Implement reorder functionality
      console.log('Reordering order:', order);
    },

    storeDetails(storeId) {
      this.$router.push(`/stores/store/${storeId}`);
      //this.$router.push({ name: "store", params: { storeId } });
    },
  }
}
</script>
    
<style>
.h1 {
  font-size: 2.8rem;
  font-weight: 700 !important;
}

.loadingItem {
  align-items: center;
  justify-content: center;
  display: flex;
}

.action-panel {
  margin-bottom: 10px;
  margin-right: 5px;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f8f9fa;
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 900;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.card-link {
  font-weight: 700 !important;
  color: #1967d2 !important;

}

.blue-font {
  color: #1967d2 !important;
}

.mt-7 {
  margin-top: 7rem;
}

label {
  font-size: 0.5rem !important;
}


.btn-blue {
  color: #1967d2 !important;
  background-color: #e8f0fe !important;
  border-color: #e8f0fe !important;
  border-radius: 2rem !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.btn-blue:focus,
.btn-blue:focus {
  color: #1967d2 !important;
  background-color: #e8f0fe !important;
  border-color: #e8f0fe !important;
  border-radius: 2rem !important;
  font-weight: 600 !important;
  box-shadow: 0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
}

.form-control:focus,
.form-control:focus {

  box-shadow: 0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #349368;
  border-color: #1967d2;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 187, 144, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(91, 187, 144, 0.5);
}

.icon-name {
  box-sizing: border-box;
  left: 50%;
  padding: 0 8px;
  position: absolute;
  top: 88px;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  width: 100%;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.icon-asset {
  display: block;
  font-size: 40px;
  left: 50%;
  position: absolute;
  top: 40px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 88px;
}

.material-icons {
  font-size: 60px;
}

.view-btn {

  border-color: transparent !important;
  background-color: transparent !important
}

.icon-out {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background: transparent;
  border: 0;
  border-radius: 8px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  height: 128px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 96px;
}


.card-clickable {
  cursor: pointer;
  transition: transform 0.2s;
}

.card-clickable:hover,
.card-clickable:focus,
.card-clickable:active {
  transform: scale(1.02);
}

.card-clickable .card-body {
  transition: background-color 0.2s;
}

.card-clickable:hover .card-body,
.card-clickable:focus .card-body,
.card-clickable:active .card-body {
  background-color: rgba(0, 0, 0, 0.05);
}

.control-label {
  color: #656b73;
  text-transform: none;
  letter-spacing: 0;
  text-align: inherit;
  height: 16px;
  font-size: 12px;
  font-weight: 600;
}
</style>
    