<template>
  <div class="row mt-7">
    <b-container>
      <div class="col-md-4 offset-md-4 col-sm-10 offset-sm-1">
      <form id="login-form" role="form" style="display: block;" @submit.prevent="onSubmit">
        <h1 class="text-center font-weight-bold mb-5">Login</h1>
        <div class="form-group">
          <label for="password" class="control-label">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            class="form-control form-control-lg"
            v-model="email"
            required
          />
        </div>
        <div class="form-group">
          <label for="password" class="control-label">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            class="form-control form-control-lg"
            placeholder=""
            v-model="password"
            required
          />
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-secondary btn-lg font-weight-bold" style="width: 100%" :disabled="isLoading">
            <i v-if="isLoading" class="fa fa-spinner fa-spin" />
            Log in
          </button>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-center font-weight-bold">
                <router-link to="/register">
                  <a>Register</a>
                </router-link>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="text-center small">
                <router-link to="/password">
                  <a>Forgot Password</a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    </b-container>
    
  </div>
</template>

<script>
import {
  mapActions
} from 'vuex';
export default {
  data() {
    return {
      email: '',
      password: '',
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['addMessage', 'clearMessage', 'loginWithEmail']),
    onSubmit() {
      this.isLoading = true
      let data = {
        email: this.email,
        password: this.password
      }
      this.loginWithEmail(data).then(() => {
        this.clearMessage();
        console.log();
        this.$router.push({
          name: 'stores'
        });
      }).catch((error) => {
        let message_obj = {
          message: error.message,
          messageClass: "danger",
          autoClose: true
        }
        this.addMessage(message_obj);
      }).then(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

.mt-7{
  top: 50% !important
}

.control-label {
    color: #656b73;
    text-transform: none;
    letter-spacing: 0;
    text-align: inherit;
    height: 16px;
    font-size: 14px !important;
    font-weight: 600;
}

.form-control {
    background: #f7f7f7;
    border-radius: 4px;
    border: 1px solid #f7f7f7;
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    display: block;
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    padding: 0 0 0 12px;
    margin-bottom: 16px;
    transition: box-shadow .15s ease-out .15s,border-color .15s ease-out,background .15s ease-out .15s,color .15s ease-out;
    width: 100%;
}
a{
  color:#1967d2 !important
}

a:hover {
  text-decoration: none;
}
</style>
