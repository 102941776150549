<template>
  <div class="container">
    <div class="row mt-7">
      <div class="col-md-8">
        <h1 class="font-weight-bold">{{ storeName }}</h1>
        <router-link :to="`/cart/${storeId}`" class="btn ml-0 float-left btn-dark btn-black navbar-btn d-flex align-items-center" tag="button" v-if="itemsInCartNo > 0">
          <span class="material-symbols-outlined font-weight-bold">
            shopping_cart_checkout
          </span>
          <span class="ml-3">View Order</span>
          <!-- <span class="badge badge-light">{{ numItems }} (R {{ cartValue }})</span> -->
          <span class="badge badge-light ml-3" >{{ numItems }}</span>
        </router-link>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="name" class="control-label"> Filter products:</label>
          <input type="text"    class="form-control" id="name" v-model="searchText">
        </div> 
      </div>
    </div>
    <div class="row p-4 justify-content-center" v-if="isLoading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else class="row mt-4">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Size</th>
            <!-- <th>Price</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, productId) in filteredProducts" :key="productId">
            <td>{{ product.name }}</td>
            <td>{{ product.size }}</td>
            <!-- <td>{{ product.price }}</td> -->
            <td>
              <button class="btn-dark btn-sm font-weight-bold" @click="addToCart(product)">Add to Cart</button>
            </td>
          </tr>
          <tr v-if="Object.keys(filteredProducts).length === 0">
            <td colspan="4">No matching products found.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
import { ref } from '../../config/firebaseConfig';
import { mapGetters, mapState } from 'vuex';
import { mapActions } from 'vuex';

export default {
  props: ['storeId'],
  data() {
    return {
      isLoading: true,
      products: {},
      searchText: '',
      storeName:'',
      storeEmail:'',
      showCartBtn: false,
      itemsInCartNo: 0,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser', 'cartListItem']),
    ...mapState(['cart']),
    numItems() {
      // return this.$store.state.cart.cartItemList[0][this.storeId].reduce((total, item) => {
      //   total += item.quantity;
      //   return total
      // }, 0);
      const cartlist = this.$store.state.cart.cartItemList.find(element => element.storeId == this.storeId);
      return cartlist[this.storeId].length;
    },
    filteredProducts() {
      // console.log("filtered");
      if (!this.products && !this.searchText) {
        return [];
      }else if(this.products && !this.searchText){
        return this.products;
      }else{
        return Object.entries(this.products).reduce((filtered, [productId, product]) => {
          const search = this.searchText.toLowerCase();
          const nameMatch = product.name.toLowerCase().includes(search);
          // const sizeMatch = product.size.toString().toLowerCase().includes(search);
          if (nameMatch) {
            filtered[productId] = product;
          }
          // console.log(filtered);
          return filtered;
        }, {});
      }
    },
  },
  created() {
    this.fetchProductIds();
    this.fetchStoreDetails(this.storeId);
    this.fetchCartList();
  },
  // mounted(){
  //   this.fetchCartList();
  //   this.fetchProductIds();
  //   this.fetchStoreDetails(this.storeId);
  // },
  methods: {
    ...mapActions(['updateCart']),
    fetchProductIds() {
      const userId = this.currentUser.uid;
      ref
        .child(`customers/${userId}/stores/${this.storeId}`)
        .once('value')
        .then(snapshot => {
          const storeData = snapshot.val();
          // console.log('fetching products');
          // console.log(snapshot.val());
          if (Array.isArray(storeData)) {
            const productIds = storeData;
            Promise.all(
              productIds.map(productId => this.fetchProductDetails(productId))
            )
              .then(products => {
                // console.log(products);
                this.products = products.reduce((acc, product) => {
                  acc[product.id] = product;
                  return acc;
                }, {});
              })
              .catch(error => {
                console.error('Error fetching products:', error);
              });
              this.isLoading = false;
          }
        })
        .catch(error => {
          console.error('Error fetching productIds:', error);
        });
    },
    fetchCartList(){
      if(this.$store.state.cart.cartItemList.length > 0){
        const storelist = this.$store.state.cart.cartItemList.find(element => element.storeId == this.storeId);
        if(storelist[this.storeId].length === 0){
          this.showCartBtn = false;
          this.itemsInCartNo = 0;
        }else{
          this.itemsInCartNo = storelist[this.storeId].length;
          this.showCartBtn = true;
        }
      }
      // const suppCart = this.$store.state.cart.cartItemList[0][this.storeId]
    },
    fetchStoreDetails(storeId) {
    // console.log(storeId);
      return ref
        .child(`stores/${storeId}`)
        .once('value')
        .then(snapshot => {
          const store = snapshot.val();
          if (store) {
            store.id = storeId;
            this.storeName = store.name;
            this.storeEmail = store.email;
            return store;
          } else {
            throw new Error(`Store with ID ${storeId} does not exist.`);
          }
        });
    },
    fetchProductDetails(productId) {
      // console.log('store', this.storeId);
      // console.log('product', productId);
      return ref
        .child(`stores/${this.storeId}/products/${productId}`)
        .once('value')
        .then(snapshot => {
          const product = snapshot.val();
          if (product) {
            product.id = productId;
            return product;
          } else {
            throw new Error(`Product with ID ${productId} does not exist.`);
          }
        });
    },
    addToCart(product){
      // console.log('STATE: ', this.$store.state.cart.cartItemList);
      // console.log("add item to cart product", product);
      //check cart first, if empty add and if not check storeId of item number and check if they match before adding
      const order = {
        storeId: this.storeId,
        item: Object.assign({}, product),
        quantity: 1,
        isAdd: true
      };
      this.updateCart(order);
      this.fetchCartList();
      // console.log("order", order);
    },
  }
};
</script>

