<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-sm navbar-light fixed-top bg-white " role="navigation">
          <div class="container">
            <router-link to="/stores" class="navbar-brand mr-auto font-weight-bold">Oneorder</router-link>
            <!-- <router-link to="/cart" class="btn ml-3 float-right btn-dark btn-black navbar-btn d-flex align-items-center" tag="button" v-if="!$route.meta.hideCart">
              <span class="material-symbols-outlined font-weight-bold">
              shopping_cart_checkout
              </span>
              <span class="badge badge-light">{{ numItems }} (R {{ cartValue }})</span>
              <span class="badge badge-light ml-3">{{ numItems }}</span>
            </router-link> -->
            <button
                class="navbar-toggler no-boarder"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTop"
                aria-controls="navbarTop"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="toggleNavbar"
                v-if="!$route.meta.hideMenu">
                <span class="material-icons menu-icon">
                  menu
                  </span>
            </button>

            <div class="collapse navbar-collapse font-weight-bold" id="navbarTop" :class="{show: isNavOpen}" v-if="!$route.meta.hideMenu">
              <ul class="navbar-nav mr-auto">

              </ul>
              <ul class="nav navbar-nav">
                <router-link to="/stores/" tag="li"  class="nav-item" active-class="active">
                  <a class="nav-link">My Suppliers</a>
                </router-link>
                <router-link to="/orders/" tag="li"  class="nav-item" active-class="active">
                  <a class="nav-link">Order History</a>
                </router-link>
                <router-link to="/login" tag="li" v-if="!isLoggedIn" class="nav-item" active-class="active">
                  <a class="nav-link">Login</a>
                </router-link>
                <li v-if="isLoggedIn" class="li-pointer nav-item">
                  <!--<a @click="logout" class="nav-link">Logout {{ userEmail }}</a>-->
                  <a @click="logout" class="nav-link">Logout</a>
                </li>
                <router-link to="/account" tag="li"  class="nav-item" active-class="active">
                  <a class="nav-link">Account</a>
                </router-link>
            
                <!--<router-link to="/register" tag="li" v-if="!isLoggedIn" class="nav-item" active-class="active">
                  <a class="nav-link">Register</a>
                </router-link>-->
                
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div v-if="isLoggedIn && !$route.meta.hideLogo" class="row">
     <div class="col-12 text-right px-0" style="padding-top: 100px;">
        <img v-if="this.customer.logo != ''" v-bind:src="this.customer.logo" alt="LOGO" style="max-height:150px;height: 100%;">
        <p class="pr-4">{{ this.customer.company }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters
} from 'vuex';
export default {
  data() {
    return {
      isNavOpen: false
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'cartValue', 'currentUser', 'cartItemList', 'customer']),
    numItems() {
      return this.cartItemList.reduce((total, item) => {
        total += item.quantity;
        return total
      }, 0);
    },
    userEmail() {
      return this.isLoggedIn ? this.currentUser.email : ''
    },
  },
  methods: {
    ...mapActions(['logout']),
    toggleNavbar() {
      this.isNavOpen = !this.isNavOpen
    }
  }
}
</script>


<style scoped lange="sass">

.menu-icon{
  font-size: 24px !important;
  font-weight: bold;
  color: black;
}

.no-boarder{
  border-radius: 0 !important;
  border-color: white !important;
}

.btn-blue{
  color: #1967d2 !important;
  background-color: #e8f0fe !important;
  border-color: #e8f0fe !important;
}

.btn-black{
  color: white !important;
  background-color: #000 !important;
  border-color: #000 !important;
  border-radius:2rem !important
}
.navbar-btn a {
  color: white;
}

.li-pointer {
  cursor: pointer;
}

.li-pointer:hover {
  cursor: pointer;
}
</style>
