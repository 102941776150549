<template>
    <div class="row p-4 justify-content-center mt-5" v-if="isLoading">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else class="container mt-5">
        <div class="row">
            <div class="col-12 text-right px-0 mb-5">
                <img v-if="this.customer.logo != ''" v-bind:src="this.customer.logo" alt="LOGO" style="max-height:150px;height: 100%;">
                <p class="pr-4">{{ this.customer.company }}</p>
            </div>
        </div>
        <b-container class="bv-example-row row  align-items-end pr-0">
            <div class="col-md-6 pr-0">
                <h1 class="font-weight-bold">Order</h1>
                <h6 class="font-weight-bold">Order No: {{ urlparams.orderNo }}</h6>
                <p>Date: {{ order.date }}</p>
            </div>
            <div class="col-md-6" v-if="order.status === 'Pending'">
                <div class="text-right mb-3">
                    <b-link class="btn btn-success px-4 py-3" @click="acceptOrder()">Accept Order</b-link>
                    &nbsp;
                    <button class="btn btn-danger px-4 py-3"  @click="declineOrder()">Decline Order</button>
                </div>
            </div>
            <div class="col-md-6" v-else>
                <div class="text-right mb-3">
                    Order status: <h6>{{ order.status }}</h6>
                </div>
            </div>
        </b-container>
        <b-container class="bv-example-row">
            <b-table :items="order.items" :fields="fields" hover small>
                <!-- Define your table columns here -->
                <template #cell(itemName)="row" style="width:60%;">
                {{ row.item.itemName }}
                </template>
                <template #cell(size)="row">
                {{ row.item.size }}
                </template>
                <template #cell(quantity)="row" class="text-center">
                    <span class="text-center">{{ row.item.quantity }}</span>
                </template>
            </b-table>
            <!-- <p class="font-weight-bold">Total: R {{ order.orderTotal }}</p> -->
        </b-container>    
        <b-container class="bv-example-row mt-4" v-if="order.notes">
            <h6>Additional Notes:</h6>
            <p class="">{{ order.notes }}</p>
        </b-container>    
    </div>
    <!-- <div class="container mt-7" v-else>
        <div v-if="isLoading === true">
            <b-container class="bv-example-row row justify-content-center">
                <b-spinner label="Loading..."></b-spinner>
            </b-container>
        </div>
        <div v-else>
            <b-container class="bv-example-row row  align-items-center">
                <div class="col-md-12">
                    <h1 class="font-weight-bold text-center">Order has been {{ order.status }}</h1>
                </div>
            </b-container>   
        </div>
    </div> -->
</template>
<script>
    import { ref } from '../../config/firebaseConfig'
    import { mapActions } from 'vuex'
    import axios from "axios";
    export default {
        order:[],
        data() {
            return {
            isLoading: true,
            order: {},
            customer:{},
            fields: [
                    { key: 'name', label: 'Name' },
                    { key: 'size', label: 'Size' },
                    { key: 'quantity', label: 'Quantity' },
                ],
            };
        },
        computed: {
            // ...mapGetters([
            // 'sendAcceptedEmailToRestaurantPHP',
            // 'sendDeclinedEmailToRestaurantPHP',
            // ]),
        },
        created() {
            this.urlparams = this.$route.query;
            this.fetchOrder();
            this.fetchCustomer();
        },
        mounted() {
            
        },
        methods:{
            ...mapActions([
            'sendAcceptedEmailToRestaurantPHP',
            'sendDeclinedEmailToRestaurantPHP',
            ]),
            fetchOrder(){
                const ordersRef = ref.child('orders/'+this.urlparams.id+'/'+this.urlparams.orderId);
                ordersRef.on('value', (snapshot) => {
                    const data = snapshot.val();
                    this.order = data;
                    // console.log(data);
                });
            },
            fetchCustomer(){
                const customerRef = ref.child('customers/'+this.urlparams.id);
                customerRef.on('value', (snapshot) => {
                    const data = snapshot.val();
                    this.customer = data;
                    this.isLoading = false;
                });
            },
            async acceptOrder(){
                const orderUpdate = ref.child('orders/'+this.urlparams.id+'/'+this.urlparams.orderId).set({
                    orderNo: this.order.orderNo,
                    storeName: this.order.storeName,
                    date: this.order.date,
                    items: this.order.items,
                    status: "Accepted"
                });

                /**remove item from notification list */
                const notificationRef = ref.child('notifications/orders/'+this.order.items[0].storeId+'/'+this.urlparams.orderId);
                notificationRef.remove();

                const storeEmailSnapshot = await ref.child('stores').child(this.order.items[0].storeId).child('email').once('value');
                const storeEmail = storeEmailSnapshot.val();

                const emailData = {
                    storeEmail:storeEmail,
                    storeName:this.order.storeName,
                    customerCompany:this.customer.company,
                    customerEmail:this.customer.email,
                    status: "Accepted",
                };
                await this.sendAcceptMail(JSON.stringify(emailData));
                // await this.sendAcceptedEmailToRestaurantPHP(JSON.stringify(emailData));
                return orderUpdate;
            },
            async declineOrder(){
                const orderUpdate = ref.child('orders/'+this.urlparams.id+'/'+this.urlparams.orderId).set({
                    orderNo: this.order.orderNo,
                    storeName: this.order.storeName,
                    date: this.order.date,
                    items: this.order.items,
                    status: "Declined"
                });

                /**remove item from notification list */
                const notificationRef = ref.child('notifications/orders/'+this.order.items[0].storeId+'/'+this.urlparams.orderId);
                notificationRef.remove();

                const storeEmailSnapshot = await ref.child('stores').child(this.order.items[0].storeId).child('email').once('value');
                const storeEmail = storeEmailSnapshot.val();

                const emailData = {
                    storeEmail:storeEmail,
                    storeName:this.order.storeName,
                    customerCompany:this.customer.company,
                    customerEmail:this.customer.email,
                    status: "Declined",
                };
                await this.sendDeclineMail(JSON.stringify(emailData));
                // await this.sendDeclinedEmailToRestaurantPHP(JSON.stringify(emailData));
                return orderUpdate;
            },
            async sendAcceptMail(emailData){
                try {
                    // const response = await axios.post('https://oneorder.clientbeta.co.za/order-response-accepted.php', emailData);
                    const response = await axios.post('https://oneorder.co.za/order-response-accepted.php', emailData);
                    console.log('Email sent successfully:', response.data);
                } catch (error) {
                    console.error('Error sending email:', error);
                }
            },
            async sendDeclineMail(emailData){
                try {
                    // const response = axios.post('https://oneorder.clientbeta.co.za/order-response-declined.php', emailData);
                    const response = axios.post('https://oneorder.co.za/order-response-declined.php', emailData);
                    console.log('Email sent successfully:', response.data);
                } catch (error) {
                    console.error('Error sending email:', error);
                }
            }
        }
    }
</script>