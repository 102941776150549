<template>
  <div class="mt-4">
    <div class="row ">
      <div class="col-md-8">
        <h1 class="font-weight-bold">Suppliers</h1>
      </div>
      <div class="col-md-4">
        <b-button v-if="isLoggedIn && isMain" variant="primary" @click="modalShow = !modalShow" class="btn btn-blue float-right">Add Supplier</b-button>
        <div>
          <b-modal @shown=getSuppliers centered content-class="shadow" v-model="modalShow" id="modal-supplier" size="lg" title="Supplier List">
            <template #modal-header="{cancel}">
              <h5>Supplier List</h5>
              <b-button size="sm" variant="primary" @click="cancel()">
                close
              </b-button>
            </template>
            <template>
              <div class="row px-4">
                <div class="col-sm-12 col-md-6 p-0">
                  <label for="supplierSearch" class="control-label"> Filter suppliers:</label>
                  <input type="text" class="form-control" id="supplierSearch" v-model="searchText">
                </div>
              </div>
              <div class="row p-4 justify-content-center" v-if="isLoading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
              <div class="row px-4" v-else>
                <div v-for="supplier in filteredSuppliersListAll" :key="supplier.id" class="col-md-6 p-2 border">
                  {{ supplier.name }}
                  <b-button variant="primary" v-b-modal.modal-supplier class="btn float-right py-1 px-4" @click="addSupplierToList(supplier)">Add</b-button>
                </div>
              </div>
            </template>
            <template #modal-footer="{}"><div class="">&nbsp;</div></template>
          </b-modal>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div v-if="isLoggedIn" class="form-group">
          <label for="searchsupp" class="control-label"> Filter suppliers:</label>
          <input type="text" class="form-control" id="searchsupp" v-model="searchKeywordSup">
        </div>
      </div>
    </div>
    <div class="row p-4 justify-content-center" v-if="isLoggedIn && isLoading">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="isLoggedIn" class="row mt-4">
      <!-- <div v-for="(store, storeId) in filteredMySuppliers" v-bind:key="storeId" class="col-xl-4 col-sm-6"> -->
      <div v-for="(store, storeId) in filteredMySuppliers" v-bind:key="storeId" class="col-xl-4 col-sm-6">
      <!-- <div class="card mb-4 card-clickable" @click.stop="goToStore(storeId)"> -->
        <div class="card mb-4 card-clickable">
          <div class="row">
            <div class="col-xl-12">
              <div class=" p-4">
                <div class="avatar-sm  mb-3 mt-1">  
                        <span
                    :class="`avatar-title rounded-circle bg-soft store-c font-size-16`"
                  >{{store.name.charAt(0)}}</span>
                  <span>
                    <b-button v-if="isMain" variant="link" @click="deleteSupplier(store.id)" style="position: absolute; right: 7%; top:6%; color: #f90000;"><BIconTrash /></b-button>
                  </span>
                </div>
                <h5 class="text-truncate pb-1 font-weight-bold">{{store.name}}</h5>
              </div>
            </div>

            <div class="col-xl-12 ml-3">
              <div class=" ">
               <!-- <div class="row">
                  <div class="col-xs-12">
                    <div>
                      <p class="text-muted mb-2 text-truncate">Products</p>
                      <h5>{{shops.products}}</h5>
                    </div>
                  </div>  
            
                </div>-->
                <div class="btn-group btn-group-sm mb-2" role="group" aria-label="">
                <!--<button type="button" @click.stop="storeDetails(store.id)" class="btn icon-out"> <span class="material-icons">
                    chevron_right
</span><span class="icon-name">Details</span></button>-->

                <button type="button" @click.stop="goToStore(store.id)" class="btn card-link">Place Order</button>
                <button type="button" @click="modalProducts = !modalProducts, selectedStoreId = store.id" class="btn card-link">Add Products</button>
                <div>
                  <b-modal @shown=getSuppliersProductList(selectedStoreId) centered content-class="shadow" v-model="modalProducts" id="modal-supplier-products" size="xl" title="Supplier Product List">
                    <template #modal-header="{cancel}">
                      <h5>Supplier Product List</h5>
                      <b-button size="sm" variant="secondary" @click="cancel()">
                        close
                      </b-button>
                    </template>
                    <template>
                      <div class="row p-4 justify-content-center" v-if="isLoading">
                        <b-spinner label="Loading..."></b-spinner>
                      </div>
                      <div v-else class="row px-4">
                        <div class="form-group col-12 p-0 d-flex flex-row justify-content-between align-items-center">
                          <input type="text" class="form-control mb-0" id="searchprod" placeholder="Search..." v-model="searchKeywordProd"  style="width: 50%;">
                          <b-button class="float-right" variant="primary" size="sm" @click="saveProductList(selectedStoreId)">Save Products</b-button>
                        </div>
                        <div v-for="product in filteredMyProducts" :key="product.id" class="col-md-6 p-2 border">
                          <div class="row">
                            <div class="col-12 col-md-10">{{ product.name }}</div>
                            <div class="col-12 col-md-2">
                              <!-- <b-form-checkbox 
                                switch 
                                size="lg"
                                :value="product.id"
                                :checked="isSelected(selectedStoreId, product.id)"
                                @change="addProductToList(selectedStoreId, product.id)"
                              ></b-form-checkbox> -->
                              <input type="checkbox"
                                :id="product.id"
                                :value="product.id"
                                :checked="isSelected(selectedStoreId, product.id)"
                                @change="addProductToList(selectedStoreId, product.id)"
                                class=""
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #modal-footer="{}"><div class="">&nbsp;</div></template>
                  </b-modal>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLoading && Object.keys(stores).length === 0">
        <h2 class="font-weight-bold">No stores found.</h2>
      </div>
    </div>
    <div v-else class="row mt-4"> 
      <div class="col-md-8">
        <h5 class="font-weight-bold">Please login to view your suppliers.</h5>
          <router-link to="/login" class="btn btn-dark btn-black  font-weight-bold" tag="button">
            Log in
          </router-link>
      </div>
      <!--<div class="store-cards">
        <div
          class="store-card"
          v-for="(store, storeId) in stores"
          :key="storeId"
          @click="goToStore(storeId)"
        >
          <h3>{{ store.name }}</h3>
          <p>{{ store.address }}</p>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { ref } from '../../config/firebaseConfig';
import { mapActions, mapGetters } from 'vuex';
import { BIconTrash } from 'bootstrap-vue';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      isLoading:true,
      isMain: false,
      customer: {},
      stores: {},
      initalSelectedSupp:{},
      initalSelected: {},
      suppliers: {},
      modalShow: false,
      modalProducts: false,
      selectedStoreId: "",
      selectedStoreDetails: {},
      selectedStoreProducts: {},
      selectedProducts: {},
      searchText:"",
      searchKeywordSup:"",
      searchKeywordProd:"",
    };
  },
  components:{
    BIconTrash
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser']),
    filteredSuppliersListAll() {
      // console.log("filtered", this.suppliers);
      if (!this.suppliers && !this.searchText) {
        return [];
      }else if(this.suppliers && !this.searchText){
        return this.suppliers;
      }else{
        return Object.entries(this.suppliers).reduce((filtered, [productId, product]) => {
          const search = this.searchText.toLowerCase();
          const nameMatch = product.name.toLowerCase().includes(search);
          // const sizeMatch = product.size.toString().toLowerCase().includes(search);
          if (nameMatch) {
            filtered[productId] = product;
          }
          // console.log(filtered);
          return filtered;
        }, {});
      }
    },
    filteredMySuppliers() {
      if (!this.stores && !this.searchKeywordSup) {
        return [];
      }else if(this.stores && !this.searchKeywordSup){
        return this.stores;
      }else{
        const keyword = this.searchKeywordSup.toLowerCase();
        // console.log(this.stores);
        // console.log(Object.values(this.stores).filter(store => store.name.toLowerCase().includes(keyword)));
        return Object.values(this.stores).filter(
          store => 
          store.name.toLowerCase().includes(keyword)
        );
      }
    },
    filteredMyProducts() {
      if (!this.selectedStoreProducts && !this.searchKeywordProd) {
        return [];
      }else if(this.selectedStoreProducts && !this.searchKeywordProd){
        return this.selectedStoreProducts;
      }else{
        const keyword = this.searchKeywordProd.toLowerCase();
        // console.log(keyword);
        // console.log(Object.values(this.selectedStoreProducts).filter(store => store.name.toLowerCase().includes(keyword)));
        return Object.values(this.selectedStoreProducts).filter(
          product => 
          product.name.toLowerCase().includes(keyword)
        );
      }
    },
  },
  created() {
    // if (this.isLoggedIn) {
    //   try {
    //     this.fetchMyDetails();
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     this.fetchStores();
    //   }
    //   // setTimeout(() => this.fetchStores(), 3000);
    // }
    if (this.isLoggedIn) {
      this.fetchMyDetails(() => {
        this.fetchStores()
      });
    }
  },
  methods: {
    fetchMyDetails: function(callback){
      const userId = this.currentUser.uid;
      ref.child(`customers/${userId}`).on('value', snapshot => {
        const details = snapshot.val();
        this.customer = details;
        this.isMain = details.franchiseStores.isMain;
        callback()
      });
    },
    fetchStores: function() {
      // const userId = this.currentUser.uid;
      let userId = '';
      if(!this.isMain){
        userId = this.customer.franchiseStores.mainId;
        // console.log(this.customer)
      }else{
        userId = this.currentUser.uid;
        // console.log(userId);
      }
      ref.child(`customers/${userId}/stores`).on('value', snapshot => {
        const storeIds = snapshot.val();
        // console.log(storeIds)
        if (storeIds) {
          Promise.all(
            Object.keys(storeIds).map(storeId => this.fetchStoreDetails(storeId))
          )
            .then(stores => {
              this.stores = stores.reduce((acc, store) => {
                acc[store.id] = store;
                return acc;
              }, {});
              
              // console.log(this.customer.stores)
              const initialStoreProducts = {};
              if(this.isMain){
                for (const storeId in storeIds) {
                  const products = storeIds[storeId];
                  if (products && Object.keys(products).length > 0) {
                    initialStoreProducts[storeId] = storeIds[storeId];
                  }
                }
              }else{
                for (const storeId in this.customer.stores) {
                  const products = this.customer.stores[storeId];
                  if (products && Object.keys(products).length > 0) {
                    initialStoreProducts[storeId] = this.customer.stores[storeId];
                  }
                }
              }
              // console.log(initialStoreProducts);
              this.initalSelected = initialStoreProducts;
              this.isLoading = false;
            })
            .catch(error => {
              console.error('Error fetching stores:', error);
            });
        }else{
          this.stores = {};
          // console.log(this.stores.length)
          this.isLoading = false;
        }
      });
    },
    // fetchStores() {
    //   const userId = this.currentUser.uid;
    //   ref.child(`customers/${userId}/stores`).on('value', (snapshot) => {
    //     const stores = snapshot.val();
    //     if (stores) {
    //       const filteredStores = {};
    //       for (const storeId in stores) {
    //         const products = stores[storeId];
    //         if (products && Object.keys(products).length > 0) {
    //           filteredStores[storeId] = stores[storeId];
    //         }
    //       }
    //       this.stores = filteredStores;
    //       console.log(this.stores);
    //       this.initializeSelectedProducts();
    //     } else {
    //       this.stores = {}; // Set empty stores if no data is available
    //     }
    //   });
    // },
    ...mapActions(['addMessage']),
    fetchStoreDetails(storeId) {
      return ref
        .child(`stores/${storeId}`)
        .once('value')
        .then(snapshot => {
          const store = snapshot.val();
          if (store) {
            store.id = storeId;
            return store;
          } else {
            throw new Error(`Store with ID ${storeId} does not exist.`);
          }
        });
    },
    goToStore(storeId) {
      this.$router.push({
        name: 'store',
        params: {
          storeId: storeId
        }
      });
    },
    getSuppliers(){
      this.isLoading = true;
      ref.child(`stores/`).on('value', snapshot => {
        const supplierIds = snapshot.val();
        if (supplierIds) {
          this.suppliers = supplierIds;
        }
        this.isLoading = false;
      });
    },
    deleteSupplier(storeId){
      Swal.fire({
        title: "Are you sure you want to delete this supplier?",
        text: "You will have to re-add the supplier to your dashboard!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3eaf7c",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          const delRef = ref.child(`customers/${this.currentUser.uid}/stores/${storeId}`);
          delRef.remove();
          // console.log(`deleted ${storeId}`)
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
            confirmButtonColor: "#3eaf7c",
          });
        }
      });
    },
    addSupplierToList(supplier){
      if (this.currentUser.uid) {
        const products = supplier.products;
        const uid = this.currentUser.uid;
        const data = {};
        const supId = supplier.id;
        data[supId] = {"0":Object.values(products)[0].id};
        ref.child(`customers/${uid}/stores`).update(data)
          .then(() => {
            this.modalShow = false;
            console.log('Supplier Added Successfully');
          })
          .catch((error) => {
            console.error('Error Adding Supplier:', error);
          });
      } else {
        console.error('User not logged in!');
      }
    },
    getSuppliersProductList(supplierId){
      this.isLoading = true;
      if (supplierId) {
        this.fetchStoreDetails(supplierId).then(store => {
          this.selectedStoreProducts = store.products;
          // console.log(this.selectedStoreProducts);
          // this.initializeSelectedProducts();
          this.isLoading = false;
        });
      }
    },
    addProductToList(storeId, productId) {
      // const selectedProducts = this.selectedProducts[storeId];
      const selectedProducts = this.initalSelected[storeId];
      const index = selectedProducts.indexOf(productId);
      // console.log(selectedProducts);

      if (index > -1) {
        selectedProducts.splice(index, 1);
      } else {
        selectedProducts.push(productId);
      }
    },
    saveProductList(storeId) {
      const selectedProductIds = this.initalSelected[storeId];
      // console.log(this.currentUser.uid)

      if (this.currentUser.uid) {
        const uid = this.currentUser.uid;
        ref.child(`customers/${uid}/stores/${storeId}`).set(selectedProductIds)
          .then(() => {
            this.modalProducts = false;
            this.searchKeywordProd = "";
            console.log('Selection saved successfully!');
          })
          .catch((error) => {
            console.error('Error saving selection:', error);
          });
      } else {
        console.error('User not logged in!');
      }
    },
    isSelected(storeId, productId) {
      if(productId){
        return this.initalSelected[storeId].includes(productId);
      }
    },
  }
};
</script>
<style>

.store-cards {
  display: flex;
  flex-wrap: wrap;
}

.store-card {
  width: 300px;
  height: 200px;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}

.h1 {
    font-size: 2.8rem;
    font-weight: 700 !important;
}
    .loadingItem {
      align-items: center;
      justify-content: center;
      display: flex;
    }
    
    .action-panel {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    .avatar-sm {
    height: 3rem;
    width: 3rem;
}
.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f8f9fa;
    color: black;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 900;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

.card-link{
    font-weight: 700 !important;
    color: #1967d2 !important;
  
}

.mt-7{
    margin-top: 7rem;
}

label{
    font-size: 0.5rem !important;
}


.btn-blue{
  color: #1967d2 !important;
  background-color: #e8f0fe !important;
  border-color: #e8f0fe !important;
  border-radius: 2rem !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  -webkit-box-shadow:none !important;
}

.btn-blue:focus, .btn-blue:focus{
  color: #1967d2 !important;
  background-color: #e8f0fe !important;
  border-color: #e8f0fe !important;
  border-radius: 2rem !important;
  font-weight: 600 !important;
  box-shadow: 0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
  -webkit-box-shadow:0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
}

.form-control:focus, .form-control:focus{
 
  box-shadow: 0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
  -webkit-box-shadow:0 0 0 0.2rem rgba(194, 231, 255, 0.5) !important;
}

.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #349368;
    border-color: #1967d2;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 187, 144, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(91, 187, 144, 0.5);
}
.icon-name{
    box-sizing: border-box;
    left: 50%;
    padding: 0 8px;
    position: absolute;
    top: 88px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    width: 100%;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.icon-asset{
    display: block;
    font-size: 40px;
    left: 50%;
    position: absolute;
    top: 40px;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    max-width: 88px;
}
.material-icons{
    font-size: 60px;
}

.view-btn{

    border-color:transparent !important;
    background-color:transparent !important
}
.icon-out{
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background: transparent;
    border: 0;
    border-radius: 8px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    height: 128px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 96px;
}


.card-clickable {
  cursor: pointer;
  transition: transform 0.2s;
}

.card-clickable:hover,
.card-clickable:focus,
.card-clickable:active {
  transform: scale(1.02);
}

.card-clickable .card-body {
  transition: background-color 0.2s;
}

.card-clickable:hover .card-body,
.card-clickable:focus .card-body,
.card-clickable:active .card-body {
  background-color: rgba(0, 0, 0, 0.05);
}

    </style>
    