import { ref, firebaseAuth } from '../config/firebaseConfig';
import axios from "axios";
//import moment from 'moment'
import {router} from "../main.js"
import 'firebase/firestore';


// export const updateCart = ({
//   commit
// }, orderData) => {
//   // console.log("update cart actions", item);
//   // TODO: Call service
//   commit('UPDATE_CART', orderData);
//   /*if (isAdd) {
//     let message_obj = {
//       message: `Add ${item.name} to cart successfully`,
//       messageClass: "success",
//       autoClose: true
//     }
//     commit('ADD_MESSAGE', message_obj);
//   }*/
// }
export const updateCart = ({
  commit
}, {storeId, item, quantity, isAdd}) => {
  // console.log("update cart actions", item);
  // TODO: Call service
  commit('UPDATE_CART', {storeId, item, quantity, isAdd});
  /*if (isAdd) {
    let message_obj = {
      message: `Add ${item.name} to cart successfully`,
      messageClass: "success",
      autoClose: true
    }
    commit('ADD_MESSAGE', message_obj);
  }*/
}

export const removeItemInCart = ({commit}, {storeId, item}) => {
	commit('REMOVE_CART_ITEM', {storeId, item});
}

export const registerByEmail = (_, {email, password, contact, company, telephone, address}) => {


	firebaseAuth().createUserWithEmailAndPassword(email, password)
        .then(userCredential => {
          const user = userCredential.user;

          // Add user to Realtime Database
          const databaseRef = ref.child('customers/'+ user.uid);
          databaseRef.set({
            email: user.email,
            telephone: telephone,
            address: address,
            contact: contact,
			company: company,
			id:user.uid
          })
            .then(() => {
              console.log('User registered and added to Realtime Database!');
            })
            .catch(error => {
              console.error('Error adding user to Realtime Database:', error);
            });
        })
        .catch(error => {
          console.error('Error registering user:', error);
        });



	return; //firebaseAuth().createUserWithEmailAndPassword(email, password);
}

export const logout = ({commit}) => {
  commit('SET_CART', []); // clear current cart
  //return firebaseAuth().signOut();

  return firebaseAuth().signOut()
    .then(() => {
      // Redirect the user to the home page
      router.push({ name: 'login' });
    })
    .catch((error) => {
      // Handle any error that occurred during sign out
      console.log('Error during sign out:', error);
    });
}

export function loginWithEmail (_, {email, password}) {
  return firebaseAuth().signInWithEmailAndPassword(email, password);
  
}

export function listenToProductList({ commit }, {uid}) {
	/*return ref.child("products").on('value', (products) => {
		commit('UPDATE_PRODUCT_LIST', products.val());
    console.log("listenToProducts",products.val());
    if(uid){
      console.log("listenToProducts",uid);
    }else {
      
      console.log("listenToProducts: User has not logged in");
    }
    
	});*/
  const userId = uid;
  return ref.child(`customers/${userId}/stores`).on('value', snapshot => {
    const storeIds = snapshot.val();
    if (storeIds) {
      Promise.all(
        Object.keys(storeIds).map(storeId => fetchStoreDetails(storeId))
      )
        .then(stores => {
          stores = stores.reduce((acc, store) => {
            acc[store.id] = store;
            return acc;
          }, {}); commit('UPDATE_PRODUCT_LIST', stores);
          // console.log("listenToProducts",stores);
        })
        .catch(error => {
          console.error('Error fetching stores:', error);
        });

        
    }
  });
}

export function getCustomerDetails({ commit },{uid}) {
  const userId = uid;
  return ref.child(`customers/${userId}`).on('value', snapshot => {
    const custdetails = snapshot.val();
    if (custdetails) {
      commit('UPDATE_CUSTOMER_DETAILS', custdetails);
    }
  });
}

export function fetchStores() {
  const userId = this.currentUser.uid;
  ref.child(`customers/${userId}/stores`).on('value', snapshot => {
    const storeIds = snapshot.val();
    if (storeIds) {
      Promise.all(
        Object.keys(storeIds).map(storeId => this.fetchStoreDetails(storeId))
      )
        .then(stores => {
          this.stores = stores.reduce((acc, store) => {
            acc[store.id] = store;
            return acc;
          }, {});
        })
        .catch(error => {
          console.error('Error fetching stores:', error);
        });
    }
  });
}

export function fetchStoreDetails(storeId) {
  return ref
    .child(`stores/${storeId}`)
    .once('value')
    .then(snapshot => {
      const store = snapshot.val();
      if (store) {
        store.id = storeId;
        return store;
      } else {
        throw new Error(`Store with ID ${storeId} does not exist.`);
      }
    });
}

export function getShoppingCart({commit}, {uid, currentCart}) {
	if (uid) {

    
		// console.log("getShoppingCart",uid);
		return ref.child("cart/" + uid).once('value').then((cart) => {
			// console.log(cart.val());
			if (cart.val() && (!currentCart || currentCart.length == 0)) {
				commit('SET_CART', cart.val());
			}
		});
	} else {
		// console.log("User has not logged in");
	}
}

export function saveShoppingCart(_, {uid, cartItemList}) {
	// console.log("ACTIONS saveShoppingCart");
	// console.log("CART DATA", cartItemList);
	return ref.child("cart/" + uid).set(cartItemList);
}

export async function saveToTransaction(_, { uid, cartItemList, notes }) {
  // console.log(uid,cartItemList, notes);
  const customerEmailSnapshot = await ref.child('customers').child(uid).child('email').once('value');
  const customerEmail = customerEmailSnapshot.val();
  const customerCompanySnapshot = await ref.child('customers').child(uid).child('company').once('value');
  const customerCompany = customerCompanySnapshot.val();
  
  const ordersRef = ref.child("orders");

  // Group cart items by store
  const itemsByStore = {};
  cartItemList.forEach((item) => {
    const storeId = item.storeId;
    if (!Object.prototype.hasOwnProperty.call(itemsByStore, storeId)) {
      itemsByStore[storeId] = [];
    }
    itemsByStore[storeId].push(item);
  });

  // Create separate orders for each store
  const orderPromises = Object.entries(itemsByStore).map(async ([storeId, items]) => {
    const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
    const orderNumber = `OO-${randomNumber.toString().substring(0, 8)}`;
    const newTransactionKey = ordersRef.child(uid).push().key;
    const orderDate = new Date(new Date().toLocaleString('en-ZA', {timeZone: 'Africa/Johannesburg'})).toISOString();
    console.log(orderDate)

    // Calculate the total value of items for the current store
    // const storeTotal = items.reduce((total, item) => {
    //   const itemPrice = parseFloat(item.price);
    //   const itemQuantity = parseInt(item.quantity);
    //   return total + itemPrice * itemQuantity;
    // }, 0);

    // Retrieve the store name from the database
    const storeNameSnapshot = await ref.child('stores').child(storeId).child('name').once('value');
    const storeName = storeNameSnapshot.val();

    const orderData = {
      // orderTotal: storeTotal.toFixed(2), // Set the total value for the current store
      orderNo: orderNumber,
      storeName: storeName,
      //date: moment(new Date().toISOString()).format('MMMM Do YYYY'),
      date: orderDate,
      items: items,
      notes: notes,
      status: 'Pending',
    };

    const newOrderRef = ordersRef.child(uid).child(newTransactionKey);
    await newOrderRef.set(orderData);

    // Retrieve the store email from the database
    // const storeEmailSnapshot = await ref.child('stores').child(storeId).child('email').once('value');
    // const storeEmail = storeEmailSnapshot.val();

    // const storeWhatsappSnapshot = await ref.child('stores').child(storeId).child('whatsapp').once('value');
    // const storeWhatsapp = storeWhatsappSnapshot.val();

    const storeInfoSnapshot = await ref.child('stores').child(storeId).once('value');
    const storeInfo = storeInfoSnapshot.val();

    /** Save data for notification/followup mail */
    const notificationRef = ref.child('notifications').child('orders');
    const newNotificationRef = notificationRef.child(storeId).child(newTransactionKey);
    const notificationData = {
      storeId: storeId,
      storeEmail: storeInfo.email,
      storeAltEmail: storeInfo.altEmail === undefined ? '' : storeInfo.altEmail,
      storeWhatsapp: storeInfo.whatsapp,
      customerId: uid,
      customerEmail:customerEmail,
      customerCompany:customerCompany,
      orderData: orderData,
      orderId: newTransactionKey,
      date: orderDate,
    };
    await newNotificationRef.set(notificationData);

    // console.log('store email',storeEmail);
    // console.log('store whatsapp',storeWhatsapp);

    // Send email using PHP with PHPMailer
    const emailData = {
      storeId: storeId,
      storeEmail: storeInfo.email,
      storeAltEmail: storeInfo.altEmail,
      storeWhatsapp: storeInfo.whatsapp,
      customerId: uid,
      customerEmail:customerEmail,
      customerCompany:customerCompany,
      orderData: orderData,
      orderId: newTransactionKey
    };
    // console.log('emaildata',JSON.stringify(emailData));
    // await sendEmailWithPHP(emailData);
    await sendConfirmationEmailToSupplierPHP(JSON.stringify(emailData));

    return true;
  });

  // Wait for all orders to be saved and emails to be sent
  await Promise.all(orderPromises);
}

// async function sendEmailWithPHP(emailData) {
//   //Live
//   // try {
//   //   // Make an HTTP POST request to your PHP script with the email data
//   //   const response = await axios.post('email.php', emailData);
//   //   console.log('Email sent successfully:', response.data);
    
//   // } catch (error) {
//   //   console.error('Error sending email:', error);
//   // }

//   //Test
//   try {
//     const response = await axios.post('http://localhost:8888/oneorder/email.php', emailData);
//     console.log('Email sent successfully:', response.data);
//   } catch (error) {
//     console.error('Error sending email:', error);
//   }
// }

async function sendConfirmationEmailToSupplierPHP(emailData) {
  //Live
  try {
    // const response = await axios.post('https://oneorder.clientbeta.co.za/order-supplier.php', emailData);
    const response = await axios.post('https://oneorder.co.za/order-supplier.php', emailData);
    console.log('Email sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending email:', error);
  }
}

// export async function sendAcceptedEmailToRestaurantPHP(emailData) {
//   //Live
//   // try {
//   //   const response = await axios.post('https://clientbeta.co.za/clients/oneorder/mails/order-supplier.php', emailData);
//   //   console.log('Email sent successfully:', response.data);
//   // } catch (error) {
//   //   console.error('Error sending email:', error);
//   // }

//   //Test
//   try {
//       const response = await axios.post('https://clientbeta.co.za/clients/oneorder/mails/order-response-accepted.php', emailData);
//       console.log('Email sent successfully:', response.data);
//   } catch (error) {
//       console.error('Error sending email:', error);
//   }
// }

// export async function sendDeclinedEmailToRestaurantPHP(emailData) {
//   //Live
//   // try {
//   //   const response = await axios.post('https://clientbeta.co.za/clients/oneorder/mails/order-supplier.php', emailData);
//   //   console.log('Email sent successfully:', response.data);
//   // } catch (error) {
//   //   console.error('Error sending email:', error);
//   // }

//   //Test
//   try {
//       const response = await axios.post('https://clientbeta.co.za/clients/oneorder/mails/order-supplier.php', emailData);
//       console.log('Email sent successfully:', response.data);
//   } catch (error) {
//       console.error('Error sending email:', error);
//   }
// }

/*export function saveToTransaction(_, {uid, cartItemList, cartValue}) {
	console.log("cartItemList:",cartItemList);
	
	const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
  let orderNumber = `OO-${randomNumber.toString().substring(0, 8)}`;


	const ordersRef = ref.child("orders");
  const newTransactionKey = ordersRef.child(uid).push().key;
	const order = {
		orderTotal: cartValue,
		orderNo: orderNumber,
		date: new Date().toISOString()
	};

	const orderData = {
		...order,
		items: cartItemList
	};

	const newOrderRef = ordersRef.child(uid).child(newTransactionKey);

 
  

	
   
	return newOrderRef.set(orderData)
}*/

/******* 
export function saveToTransaction(_, {uid, cartItemList, cartValue}) {
	console.log("cartItemList:",cartItemList);
	console.log(cartValue);


	const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
    let orderNumber = `OO-${randomNumber.toString().substring(0, 8)}`;

	/*
	const emailData = {
        to: 'phebeon.vera@gmail.com', 
        subject: orderNumber,
        message: 'Order Information: ' + JSON.stringify(cartItemList)
        };

        console.log(JSON.stringify(cartItemList));
        
        axios.post('htts://www.opencharge.io/sendEmail.php', emailData)
        .then(response => {
          console.log('Email sent successfully');
          console.log(response);
        })
        .catch(error => {
          console.error('Error sending email:', error);
        });    


*/
	/*******	




	const ordersRef = ref.child("orders");
  const newTransactionKey = ordersRef.child(uid).push().key;
	const order = {
		// Your order data here
		orderTotal: cartValue,
		orderNo: orderNumber,
		date: new Date().toISOString() // Add the current date/time
	};

	const orderData = {
		...order,
		items: cartItemList
	};

	const newOrderRef = ordersRef.child(uid).child(newTransactionKey);

  console.log(orderData);
  console.log(newOrderRef);
  

		// Save data to Firestore to trigger email function
		/*const firestore = firebase.firestore();
		const collectionRef = firestore.collection('orders');
		collectionRef.add(orderData)
		  .then(() => {
			console.log('Data saved to Firestore!');
		  })
		  .catch(error => {
			console.error('Error saving data to Firestore:', error);
		  });*/
	
/*******     
	//return newOrderRef.set(orderData)
}*/

