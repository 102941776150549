const state = {
	isLoading: true,
	customerDetails: [],
}

const mutations = {
	'UPDATE_CUSTOMER_DETAILS' (state, customerDetails) {
		state.customerDetails = customerDetails;
		state.isLoading = false;
	}
}

const actions = {}

const getters = {
	customer: (state) => {
		return state.customerDetails;
	},
	isCustomerLoading: (state) => {
		return state.isLoading;
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}